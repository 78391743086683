import { googleLogout } from "@react-oauth/google";
import { callAPI, callAuthAPI, callSingleFileAPI, callFileAPI } from "./Utils";

const API_ENDPOINT =
  process.env.REACT_APP_BACKEND_URL || "http://localhost:4000";

export async function signinWithGoogle(code: string) {
  const response = await callAuthAPI({
    path: `${API_ENDPOINT}/signin_v1/google`,
    method: "POST",
    body: { code },
  });
  return response;
}

export async function getUserSpaces() {
  const response = await callAPI({
    path: `${API_ENDPOINT}/api/v1/spaces/`,
    method: "GET",
  });
  return response;
}

export async function getUserConversations() {
  const workspace_id = localStorage.getItem("workspace_id");
  const response = await callAPI({
    path: `${API_ENDPOINT}/api/v1/workspaces/${workspace_id}/chatsapi/conversations`,
  });
  return response;
}

export async function getConversationChat(conversationId: string) {
  if (!conversationId) return null;
  const workspace_id = localStorage.getItem("workspace_id");
  const response = await callAPI({
    path: `${API_ENDPOINT}/api/v1/workspaces/${workspace_id}/chatsapi/conversations/${conversationId}`,
  });
  return response;
}

export async function sendConversationQuery(
  message: string,
  conversationId: string,
  model: string
) {
  const workspace_id = localStorage.getItem("workspace_id");
  // const model = localStorage.getItem("model");
  const response = await callAPI({
    path: `${API_ENDPOINT}/api/v1/workspaces/${workspace_id}/chatsapi/conversations/${conversationId}/chat`,
    method: "POST",
    query: { message, model },
    body: { message, model },
  });
  if (response) {
    const tokMessage = response["output"] || response["tok"];
    const sourcesObj =
      tokMessage?.includes("Hmm, I'm not sure") ||
      tokMessage?.includes("how can I help you")
        ? []
        : response["sources"] || [];

    let chartData = [];

    if (response["chat_metadata"] && Array.isArray(response["chat_metadata"])) {
      chartData = response["chat_metadata"];
    } else if (typeof response["chat_metadata"] === "object") {
      chartData = response["chat_metadata"]["graph_data"] || [];
    } else {
      chartData = [];
    }

    chartData.map((item: any) => {
      const amount = parseFloat(item.amount);
      if (!isNaN(amount) && amount < 0) {
        item.amount = (-amount).toString();
      }
    });
    return {
      message: tokMessage?.replaceAll("sanjay-alvyl", "ashok-yannam"),
      sources: sourcesObj,
      chartData,
      image: response.image,
      chat_html: response["chat_html"],
      chat_metadata: response["chat_metadata"],
    };
  } else {
    return { message: "Please try asking again", sources: [] };
  }
}

export async function logout() {
  googleLogout();
  localStorage.clear();
  window.location.pathname = "/";
}

export async function uploadDocumentsAdmin(file: any, role: string) {
  const workspace_id = localStorage.getItem("workspace_id");
  const response = await callSingleFileAPI({
    path: `${API_ENDPOINT}/api/v1/workspaces/${workspace_id}/docsapi/documents/add?role=${role}`,
    method: "POST",
    file: file,
  });
  return response;
}

export async function uploadCsvDocumentsAdmin(file: any, role: string) {
  const workspace_id = localStorage.getItem("workspace_id");
  const response = await callSingleFileAPI({
    path: `${API_ENDPOINT}/api/v1/workspaces/${workspace_id}/docsapi/csv_documents/add?role=${role}`,
    method: "POST",
    file: file,
  });
  return response;
}

export async function updateVetorStore() {
  const workspace_id = localStorage.getItem("workspace_id");
  try {
    const response = await callAPI({
      path: `${API_ENDPOINT}/api/v1/workspaces/${workspace_id}/docsapi/vectorstore/update`,
      method: "POST",
    });
    const graphResponse = await callAPI({
      path: `${API_ENDPOINT}/api/v1/workspaces/${workspace_id}/docsapi/graphstore/update`,
      method: "POST",
    });
    return { response, graphResponse };
  } catch (error) {
    console.error("Error during update:", error);
    throw error;
  }
}

export async function getUploadedDocumentsAdmin() {
  const workspace_id = localStorage.getItem("workspace_id");
  const response = await callAPI({
    path: `${API_ENDPOINT}/api/v1/workspaces/${workspace_id}/docsapi/documents`,
    method: "GET",
  });
  return response;
}

export async function AddEnv(data: any) {
  try {
    const response = await callAPI({
      path: `${API_ENDPOINT}/api/v1/add-env`,
      method: "POST",
      body: data,
    });
    return response;
  } catch (error) {
    console.error("Error adding environment variable:", error);
    throw error;
  }
}

export async function AddMultipleEnv(data: any) {
  try {
    const response = await callAPI({
      path: `${API_ENDPOINT}/api/v1/add-multiple-env`,
      method: "POST",
      body: data,
    });
    return response;
  } catch (error) {
    console.error("Error adding environment variables:", error);
    throw error;
  }
}

export async function UpdateMultipleEnv(data: any) {
  try {
    const response = await callAPI({
      path: `${API_ENDPOINT}/api/v1/multiple-env`,
      method: "PUT",
      body: data,
    });
    return response;
  } catch (error) {
    console.error("Error adding environment variables:", error);
    throw error;
  }
}

export async function DeleteMultipleEnv(data: any) {
  try {
    const response = await callAPI({
      path: `${API_ENDPOINT}/api/v1/multiple-env`,
      method: "DELETE",
      body: data,
    });
    return response;
  } catch (error) {
    console.error("Error deleting environment variable:", error);
    throw error;
  }
}

export async function DeleteEnv(envid: any) {
  try {
    const response = await callAPI({
      path: `${API_ENDPOINT}/api/v1/env/${envid}`,
      method: "DELETE",
    });
    return response;
  } catch (error) {
    console.error("Error deleting environment variable:", error);
    throw error;
  }
}

export async function updateEnv(data: any, envid: any) {
  try {
    const response = await callAPI({
      path: `${API_ENDPOINT}/api/v1/env/${envid}`,
      method: "PUT",
      body: data,
    });
    console.log("Server response:", response);
    return response;
  } catch (error) {
    console.error("Error editing environment variable:", error);
    throw error;
  }
}

export async function getAllEnvs() {
  try {
    const response = await callAPI({
      path: `${API_ENDPOINT}/api/v1/envs`,
      method: "GET",
    });
    return response;
  } catch (error) {
    console.error("Error fetching environments:", error);
    throw error;
  }
}

export async function getEnvsByUserId(userid: any) {
  try {
    const response = await callAPI({
      path: `${API_ENDPOINT}/api/v1/envs/${userid}`,
      method: "GET",
    });
    return response;
  } catch (error) {
    console.error("Error fetching environments by id:", error);
    throw error;
  }
}

export async function createNewConversation(role: any) {
  const workspace_id = localStorage.getItem("workspace_id");
  const response = await callAPI({
    method: "POST",
    path: `${API_ENDPOINT}/api/v1/workspaces/${workspace_id}/chatsapi/conversations/create?role=${role}`,
  });
  return response;
}

export async function uploadLinksAdmin(links: string[], role: string) {
  const workspace_id = localStorage.getItem("workspace_id");
  for (let link of links) {
    const response = await callAPI({
      method: "POST",
      path: `${API_ENDPOINT}/api/v1/workspaces/${workspace_id}/docsapi/links/add`,
      query: { link, role },
    });
  }
  return true;
}

export async function getUploadedLinksAdmin() {
  const workspace_id = localStorage.getItem("workspace_id");
  const response = await callAPI({
    method: "GET",
    path: `${API_ENDPOINT}/api/v1/workspaces/${workspace_id}/docsapi/links`,
  });
  return response;
}

export async function saveGCPConfig(config: any) {
  const workspace_id = localStorage.getItem("workspace_id");
  const response = await callAPI({
    method: "POST",
    path: `${API_ENDPOINT}/api/v1/workspaces/${workspace_id}/docsapi/gcpconfig`,
    body: JSON.stringify(config),
  });
  return response;
}

export async function getGCPConfig() {
  const workspace_id = localStorage.getItem("workspace_id");
  const response = await callAPI({
    method: "GET",
    path: `${API_ENDPOINT}/api/v1/workspaces/${workspace_id}/docsapi/gcpconfig`,
  });
  return response;
}

export async function uploadCSVFileToConversation(
  files: any,
  conversationId: string
) {
  const workspace_id = localStorage.getItem("workspace_id");
  const response = await callFileAPI({
    path: `${API_ENDPOINT}/api/v1/workspaces/${workspace_id}/docsapi/csv_documents/add?conversation_id=${conversationId}`,
    method: "POST",
    files: files,
  });
  return response;
}

export async function shareConversationPublic(conversationId: string) {
  const workspace_id = localStorage.getItem("workspace_id");
  const response = await callAPI({
    path: `${API_ENDPOINT}/api/v1/workspaces/${workspace_id}/chatsapi/conversations/share/${conversationId}`,
    method: "POST",
  });
  return response;
}

export async function getSharedConversationPublic(conversationId: string) {
  const workspace_id = localStorage.getItem("workspace_id");
  const response = await callAPI({
    path: `${API_ENDPOINT}/api/v1/chatsapi/conversations/share/${conversationId}`,
    method: "GET",
  });
  return response;
}

export const graphQueryAPI = async (
  query_type: string,
  document_names: string,
  role: string
) => {
  try {
    // @ts-ignore
    // formData.append("document_names", JSON.stringify(["llama.pdf"]));

    const response = await callAPI({
      path: `${API_ENDPOINT}/api/v1/graph_query`,
      method: "POST",
      query: {
        query_type: query_type ?? "chunksEntities",
        document_names: JSON.stringify([document_names]),
        role: role,
      },
    });
    return response;
  } catch (error) {
    console.log("Error Posting the Question:", error);
    throw error;
  }
};
export default graphQueryAPI;

export const infoAPI = async (chunk_ids: string, role: string) => {
  try {
    const response = await callAPI({
      path: `${API_ENDPOINT}/api/v1/chunk_entities?chunk_ids=${chunk_ids}&role=${role}`,
      method: "POST",
    });
    return response;
  } catch (error) {
    console.log("Error:", error);
    throw error;
  }
};

export const documentStoresAPI = async () => {
  try {
    const response = await callAPI({
      path: `${API_ENDPOINT}/api/v1/docsapi/documentstores`,
      method: "GET",
      query: { skip: 0, limit: 100 },
    });
    return response;
  } catch (error) {
    console.log("Error:", error);
    throw error;
  }
};

export const getDocumentStoreAPI = async (store_id: number) => {
  try {
    const response = await callAPI({
      path: `${API_ENDPOINT}/api/v1/docsapi/documentstore/${store_id}`,
      method: "GET",
      query: { store_id: store_id },
    });
    return response;
  } catch (error) {
    console.log("Error:", error);
    throw error;
  }
};

export const createDocumentStoreAPI = async ({
  name,
  type,
  description,
  store_subtype,
}: any) => {
  try {
    const payload: any = { name, type, description };

    if (type === "system" && store_subtype) {
      payload["store_subtype"] = store_subtype;
    }

    const response = await callAPI({
      path: `${API_ENDPOINT}/api/v1/docsapi/documentstore`,
      method: "POST",
      body: payload,
    });

    return response;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const editDocumentStoreAPI = async ({
  store_id,
  name,
  type,
  description,
  store_subtype, // Include store_subtype as an optional parameter
}: {
  store_id: string;
  name: string;
  type: string;
  description: string;
  store_subtype?: string; // Mark store_subtype as optional
}) => {
  try {
    // Construct the body conditionally
    const body: any = {
      store_id,
      name,
      type,
      description,
    };

    // Add store_subtype only if it's provided (for 'system' type)
    if (type === "system" && store_subtype) {
      body.store_subtype = store_subtype;
    }

    const response = await callAPI({
      path: `${API_ENDPOINT}/api/v1/docsapi/documentstore/${store_id}`,
      method: "PUT",
      body, // Use the constructed body
    });
    return response;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const deleteDocumentStoreAPI = async (store_id: number) => {
  try {
    const response = await callAPI({
      path: `${API_ENDPOINT}/api/v1/docsapi/documentstore/${store_id}`,
      method: "DELETE",
      query: { store_id: store_id },
    });
    return response;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export async function uploadDocumentsStoreAPI({
  file,
  store_id,
}: {
  file: any;
  store_id: string;
}) {
  const response = await callSingleFileAPI({
    path: `${API_ENDPOINT}/api/v1/docsapi/documentstore/${store_id}/document`,
    method: "POST",
    file: file,
  });
  return response;
}

export const getDocumentsStoreAPI = async (store_id: number) => {
  try {
    const response = await callAPI({
      path: `${API_ENDPOINT}/api/v1/docsapi/documentstore/${store_id}/documents`,
      method: "GET",
      query: { store_id: store_id },
    });
    return response;
  } catch (error) {
    console.log("Error:", error);
    throw error;
  }
};

export const deleteDocumentsStoreAPI = async ({
  store_id,
  document_id,
}: {
  store_id: string;
  document_id: string;
}) => {
  try {
    const response = await callAPI({
      path: `${API_ENDPOINT}/api/v1/documentstore/${store_id}/document/${document_id}`,
      method: "DELETE",
      query: { store_id: store_id, document_id: document_id },
    });
    return response;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export async function updateStoreforDocumentStore({
  storeType,
  storeId,
}: {
  storeType: string;
  storeId: string;
}) {
  const workspace_id = localStorage.getItem("workspace_id");

  if (storeType === "vector store") {
    const response = await callAPI({
      path: `${API_ENDPOINT}/api/v1/workspaces/${workspace_id}/docsapi/vectorstore/update-v2?store_id=${storeId}`,
      method: "POST",
    });
    return response;
  } else if (storeType === "graph store") {
    const response = await callAPI({
      path: `${API_ENDPOINT}/api/v1/workspaces/${workspace_id}/docsapi/graphstore/update?store_id=?${storeId}`,
      method: "POST",
    });
    return response;
  } else {
    throw new Error("Invalid store type provided. Use 'vector' or 'graph'.");
  }
}

export async function getAgents() {
  try {
    const response = await callAPI({
      path: `${API_ENDPOINT}/api/v1/flowapi/agents`,
      method: "GET",
    });
    return response;
  } catch (error) {
    console.error("Error fetching Agents:", error);
    throw error;
  }
}

export async function getAgent(agentId: any) {
  try {
    const response = await callAPI({
      path: `${API_ENDPOINT}/api/v1/flowapi/agent/${agentId}`,
      method: "GET",
    });
    return response;
  } catch (error) {
    console.error("Error fetching agents by id:", error);
    throw error;
  }
}

export async function createAgent(payload: any) {
  try {
    const response = await callAPI({
      path: `${API_ENDPOINT}/api/v1/flowapi/add-agent`,
      method: "POST",
      body: payload,
    });
    return response;
  } catch (error) {
    console.error("Error adding agent:", error);
    throw error;
  }
}

export async function deleteAgent(agentId: any) {
  try {
    const response = await callAPI({
      path: `${API_ENDPOINT}/api/v1/flowapi/agent/${agentId}`,
      method: "DELETE",
    });
    return response;
  } catch (error) {
    console.error("Error deleting agent:", error);
    throw error;
  }
}

export async function updateAgent(agentId: any, payload: any) {
  try {
    const response = await callAPI({
      path: `${API_ENDPOINT}/api/v1/flowapi/agent/${agentId}`,
      method: "PUT",
      body: payload,
    });
    console.log("Server response:", response);
    return response;
  } catch (error) {
    console.error("Error editing update agent:", error);
    throw error;
  }
}

export async function getFlows() {
  try {
    const response = await callAPI({
      path: `${API_ENDPOINT}/api/v1/flowapi/flows`,
      method: "GET",
    });
    return response;
  } catch (error) {
    console.error("Error fetching Flows:", error);
    throw error;
  }
}

export async function getFlow(flowId: any) {
  try {
    const response = await callAPI({
      path: `${API_ENDPOINT}/api/v1/flowapi/flow/${flowId}`,
      method: "GET",
    });
    return response;
  } catch (error) {
    console.error("Error fetching flows by id:", error);
    throw error;
  }
}

export async function createFlow(payload: any) {
  try {
    const response = await callAPI({
      path: `${API_ENDPOINT}/api/v1/flowapi/add-flow`,
      method: "POST",
      body: payload,
    });
    return response;
  } catch (error) {
    console.error("Error adding flow:", error);
    throw error;
  }
}

export async function deleteFlow(flowId: any) {
  try {
    const response = await callAPI({
      path: `${API_ENDPOINT}/api/v1/flowapi/flow/${flowId}`,
      method: "DELETE",
    });
    return response;
  } catch (error) {
    console.error("Error deleting flow:", error);
    throw error;
  }
}

export async function updateFlow(flowId: any, payload: any) {
  try {
    const response = await callAPI({
      path: `${API_ENDPOINT}/api/v1/flowapi/flow/${flowId}`,
      method: "PUT",
      body: payload,
    });
    console.log("Server response:", response);
    return response;
  } catch (error) {
    console.error("Error while updating the flow:", error);
    throw error;
  }
}

export async function getToolGroups(type: string) {
  try {
    const response = await callAPI({
      path: `${API_ENDPOINT}/api/v1/flowapi/toolgroups?type=${type}`,
      method: "GET",
    });
    return response;
  } catch (error) {
    console.error("Error fetching tool groups:", error);
    throw error;
  }
}

export async function getToolGroup(toolGroupId: any, type: any) {
  try {
    const response = await callAPI({
      path: `${API_ENDPOINT}/api/v1/flowapi/toolgroup/${toolGroupId}?type=${type}`,
      method: "GET",
    });
    return response;
  } catch (error) {
    console.error("Error fetching toolgroup by id:", error);
    throw error;
  }
}

export async function createToolGroup(payload: any) {
  try {
    const response = await callAPI({
      path: `${API_ENDPOINT}/api/v1/flowapi/add-tool-group`,
      method: "POST",
      body: payload,
    });
    return response;
  } catch (error) {
    console.error("Error adding toolgroup:", error);
    throw error;
  }
}

export async function deleteToolGroup(toolGroupId: any) {
  try {
    const response = await callAPI({
      path: `${API_ENDPOINT}/api/v1/flowapi/toolgroup/${toolGroupId}`,
      method: "DELETE",
    });
    return response;
  } catch (error) {
    console.error("Error deleting toolGroup:", error);
    throw error;
  }
}

export async function updateToolGroup(toolGroupId: any, payload: any) {
  try {
    const response = await callAPI({
      path: `${API_ENDPOINT}/api/v1/flowapi/toolgroup/${toolGroupId}`,
      method: "PUT",
      body: payload,
    });
    return response;
  } catch (error) {
    console.error("Error while updating the flow:", error);
    throw error;
  }
}

export async function uploadLink({
  storeId,
  link,
}: {
  storeId: string;
  link: string;
}) {
  try {
    const response = await callAPI({
      path: `${API_ENDPOINT}/api/v1/docsapi/documentstore/${storeId}/link`,
      method: "POST",
      body: { url: link },
    });
    return response;
  } catch (error) {
    console.error("Error uploading link:", error);
    throw error;
  }
}

export async function getLinks(storeId: string) {
  try {
    const response = await callAPI({
      path: `${API_ENDPOINT}/api/v1/docsapi/documentstore/${storeId}/links`,
      method: "GET",
    });
    return response;
  } catch (error) {
    console.error("Error retrieving links:", error);
    throw error;
  }
}

export async function deleteLink({
  storeId,
  linkId,
}: {
  storeId: string;
  linkId: string;
}) {
  try {
    const response = await callAPI({
      path: `${API_ENDPOINT}/api/v1/docsapi/documentstore/${storeId}/link/${linkId}`,
      method: "DELETE",
    });
    return response;
  } catch (error) {
    console.error("Error deleting link:", error);
    throw error;
  }
}

export async function getTools() {
  try {
    const response = await callAPI({
      path: `${API_ENDPOINT}/api/v1/flowapi/tools`,
      method: "GET",
    });
    return response;
  } catch (error) {
    console.error("Error retrieving tools:", error);
    throw error;
  }
}

export async function getTool(toolId: any) {
  try {
    const response = await callAPI({
      path: `${API_ENDPOINT}/api/v1/flowapi/tool/${toolId}`,
      method: "GET",
    });
    return response;
  } catch (error) {
    console.error("Error fetching tool by id:", error);
    throw error;
  }
}

export async function createChatConversation(role: any) {
  const workspace_id = localStorage.getItem("workspace_id");
  const response = await callAPI({
    method: "POST",
    path: `${API_ENDPOINT}/api/v1/flowapi/${workspace_id}/conversations/create?role=${role}`,
  });
  return response;
}

export async function sendChatConversationQuery(
  message: string,
  conversationId: string
) {
  const workspace_id = localStorage.getItem("workspace_id");
  const response = await callAPI({
    path: `${API_ENDPOINT}/api/v1/flowapi/${workspace_id}/conversations/${conversationId}/chat`,
    method: "POST",
    query: { message },
    body: { message },
  });
  return response;
}

export async function uploadDocumentToS3({
  file,
  store_id,
}: {
  file: any;
  store_id: string;
}) {
  const response = await callSingleFileAPI({
    path: `${API_ENDPOINT}/api/v1/docsapi/s3/documentstore/${store_id}/document`,
    method: "POST",
    file: file,
  });
  return response;
}

export const getDocumentsFromS3 = async (
  store_id: string,
  skip: number = 0,
  limit: number = 10
) => {
  try {
    const response = await callAPI({
      path: `${API_ENDPOINT}/api/v1/docsapi/s3/documentstore/${store_id}/documents`,
      method: "GET",
      query: { skip, limit },
    });

    if (response.error) {
      throw new Error(`Error: ${response.error}`);
    }

    return response;
  } catch (error) {
    console.error("Error fetching documents from S3:", error);
    throw error;
  }
};

export const deleteDocumentFromS3 = async (store_id: any, document_id: any) => {
  try {
    const response = await callAPI({
      path: `${API_ENDPOINT}/api/v1/docsapi/s3/documentstore/${store_id}/document/${document_id}`,
      method: "DELETE",
    });

    if (response.error) {
      throw new Error(`Error: ${response.error}`);
    }

    return response;
  } catch (error) {
    console.error("Error deleting document from S3:", error);
    throw error;
  }
};
