import { useEffect, useState, useRef } from "react";
import { Avatar, Flex, Group, Menu, Text } from "@mantine/core";
import { IconChevronRight, IconLogout } from "@tabler/icons-react";
import Conversation from "../../components/Conversation";
import ConversationMD from "../../components/material-design/components/Conversation";
import {
  getUserConversations,
  getUserSpaces,
  logout,
  shareConversationPublic,
} from "../../lib/APIService";
import { useNavigate, useParams } from "react-router-dom";
import { notifications } from "@mantine/notifications";
import moment from "moment";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const Home = () => {
  const { conversationId } = useParams();
  const [openNavbar, setOpenNavbar] = useState(false);
  const [newChat, setNewChat] = useState(!!conversationId ? false : true);
  const [conversations, setConversations] = useState({} as any);
  const isAdmin = localStorage.getItem("role") === "admin";
  const navigate = useNavigate();
  const [minimizeNavbar, setMinimizeNavbar] = useState(false);
  const [model, setModel] = useState<string | null>("openAI");
  const [currentRole, setCurrentRole] = useState("");
  const [chatName, setChatName] = useState<string>("New Chat");
  const [openShareModal, setOpenShareModal] = useState<boolean>(false);
  const [openedPopover, setOpenedPopover] = useState(false);
  const [isSharedConversation, setIsSharedConversation] =
    useState<boolean>(false);
  const [showConversationList, setShowConversationList] =
    useState<boolean>(false);
  const dialogRef = useRef<HTMLDialogElement | null>(null);
  const ref = useRef<HTMLDivElement>(null);

  const fetchConversations = async () => {
    if (!localStorage.getItem("workspace_id")) {
      const spaces = await getUserSpaces();
      if (
        spaces.organizations?.length &&
        spaces.organizations[0].workspaces?.length
      ) {
        localStorage.setItem(
          "workspace_id",
          spaces.organizations[0].workspaces[0].id
        );
      }
    }
    const response = await getUserConversations();
    if (response.length) {
      const today = moment().startOf("day");
      const thisWeek = moment().startOf("week");
      const lastMonth = moment().subtract(1, "month");
      const older = moment(lastMonth).startOf("month");

      setConversations(
        response
          .sort((a: any, b: any) => {
            const time1 = new Date(a.created_at);
            const time2 = new Date(b.created_at);
            return time1 > time2 ? -1 : 1;
          })
          .reduce((conversationsByDate: any, conv: any) => {
            if (moment(conv.created_at) > today) {
              if (!conversationsByDate["today"]) {
                conversationsByDate["today"] = [];
              }
              conversationsByDate["today"].push(conv);
            } else if (moment(conv.created_at) > thisWeek) {
              if (!conversationsByDate["this_week"]) {
                conversationsByDate["this_week"] = [];
              }
              conversationsByDate["this_week"].push(conv);
            } else if (
              moment(conv.created_at).month() === moment(lastMonth).month() &&
              moment(conv.created_at).year() === moment(lastMonth).year()
            ) {
              if (!conversationsByDate["last_month"]) {
                conversationsByDate["last_month"] = [];
              }
              conversationsByDate["last_month"].push(conv);
            } else {
              if (!conversationsByDate["older"]) {
                conversationsByDate["older"] = [];
              }
              conversationsByDate["older"].push(conv);
            }
            return conversationsByDate;
          }, {})
      );
    }
  };

  const shareConversation = async () => {
    if (!!conversationId) {
      const response = await shareConversationPublic(conversationId);
      if (response.status === "ok") {
        setOpenShareModal(false);
        setIsSharedConversation(true);
      }
    }
  };

  const downloadPdf = async () => {
    if (ref.current) {
      const targetElement = ref.current;

      // Temporarily expand the container's height to capture full content
      const originalHeight = targetElement.style.height;
      const originalOverflow = targetElement.style.overflowY;
      targetElement.style.height = "auto";
      targetElement.style.overflowY = "visible";

      // Wait a moment for the layout to adjust before capturing
      setTimeout(async () => {
        const canvas = await html2canvas(targetElement, {
          scale: 2, // Higher scale for better quality
          scrollX: 0,
          scrollY: 0,
        });

        // Restore original scrolling behavior
        targetElement.style.height = originalHeight;
        targetElement.style.overflowY = originalOverflow;

        // Convert the canvas to an image and add it to a PDF
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("p", "mm", "a4");
        const imgWidth = 210;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
        pdf.save("conversation.pdf");
      }, 500); // Allow some time for layout adjustment
    }
  };

  const getUserMenu = () => {
    return (
      <Menu shadow="md" width={!minimizeNavbar ? 240 : 120}>
        <Menu.Target>
          <Group position="center" py={16} spacing={"sm"}>
            <Avatar
              size={!minimizeNavbar ? "md" : "lg"}
              src={localStorage.getItem("image")}
            ></Avatar>
            {!minimizeNavbar && (
              <Flex direction={"column"} w={160}>
                <Text truncate size={16} fw={500} color="#072D57">
                  {localStorage.getItem("firstName")}{" "}
                  {localStorage.getItem("lastName")}
                </Text>
                <Text truncate size={12} color="#737980" fw={400}>
                  {localStorage.getItem("email")}
                </Text>
              </Flex>
            )}
            {!minimizeNavbar && <IconChevronRight />}
          </Group>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item onClick={logout} icon={<IconLogout size={14} />}>
            Signout
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    );
  };

  const renderConversationList = () => {
    return (
      <div className="">
        <i
          className="fixed right cursor-pointer horizontal-padding"
          style={{ zIndex: 1 }}
          onClick={() => setShowConversationList(false)}
        >
          close
        </i>
        {Object.keys(conversations).map((key) => {
          const convs = conversations[key];
          return (
            <div key={key} className="top-padding">
              {/* <span className="conversation-history-label">
                {key.replace("_", " ")}
              </span> */}
              <div className="chip no-decoration no-border no-padding surface">
                {" "}
                {key.replace("_", " ").toLocaleUpperCase()}
                <i>chat</i>
              </div>

              <div>
                {convs?.length &&
                  convs.map((conv: any) => {
                    return (
                      <div
                        className={`group row align-start`}
                        key={conv.id}
                        style={{
                          cursor: "pointer",
                          borderRadius: "5px",
                          backgroundColor:
                            conv.id === conversationId
                              ? "rgba(0, 0, 0, 0.1)"
                              : "transparent",
                          padding: "8px 10px",
                          margin: 0,
                        }}
                        onClick={() => {
                          setNewChat(false);
                          navigate(`/home/chat/conversation/${conv.id}`);
                          setShowConversationList(false);
                        }}
                      >
                        <p className="history-label">
                          {conv["title"]
                            ? conv["title"].length < 60
                              ? conv["title"]
                              : conv["title"].slice(0, 60 - 3) + "..."
                            : "Untitled"}
                        </p>
                      </div>
                    );
                  })}
              </div>
              <hr className="small"></hr>
            </div>
          );
        })}
      </div>
    );
  };

  useEffect(() => {
    if (!conversations.length) {
      fetchConversations();
    }
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dialogRef.current &&
        !dialogRef.current.contains(event.target as Node)
      ) {
        setShowConversationList(false);
      }
    };

    // Add event listener when dialog is open
    if (showConversationList) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    // Clean up the event listener when component unmounts or dialog is closed
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showConversationList]);

  useEffect(() => {
    if (isSharedConversation) {
      const timeoutId = setTimeout(() => {
        setIsSharedConversation(false);
      }, 5000);

      return () => clearTimeout(timeoutId);
    }
  }, [isSharedConversation]);

  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        height: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          alignContent: "center",
        }}
      >
        <div className="row center-align horizontal-margin">
          <div className="chat-name">{chatName}</div>

          {chatName !== "Untitled" && chatName !== "New Chat" && (
            <>
              <div className="chip fill round small-line">
                {currentRole.toLocaleUpperCase()}
              </div>
              <i
                className="medium fill primary-text cursor-pointer"
                onClick={() => setOpenShareModal(!openShareModal)}
              >
                share
              </i>
              <i
                className="medium fill primary-text cursor-pointer"
                onClick={() => downloadPdf()}
              >
                download
              </i>
            </>
          )}
        </div>
        {/* <div className="field suffix border small">
          <select
            onChange={(e) => setModel(e.target.value)}
            value={model!}
            className="input-xs"
          >
            <option value="openAI">OpenAI</option>
            <option value="vertexAI">Vertex AI</option>
            <option value="mistralAI">Mistral AI</option>
          </select>
          <i>arrow_drop_down</i>
        </div> */}
        <div className="">
          {chatName !== "New Chat" && (
            <a href="/home/chat" style={{ display: "inline-block" }}>
              <button
                className="border"
                onClick={() => {
                  setNewChat(false);
                }}
              >
                <i>add</i>
                <span>New Chat</span>
              </button>
            </a>
          )}
          <button
            className="border"
            onClick={() => setShowConversationList(true)}
          >
            <i>history</i>
            <span>View History</span>
          </button>
          {/* {!newChat && (
            <a
              className="chip circle"
              onClick={() => setOpenShareModal(!openShareModal)}
            >
              <i>ios_share</i>
              <div className="tooltip top ">Share conversation</div>
            </a>
          )}

          {!newChat && (
            <button className="chip round" onClick={downloadPdf}>
              <span>PDF</span>
              <div className="tooltip">Download PDF</div>
            </button>
          )} */}
        </div>
      </div>
      <hr className="small" />
      {!!newChat && (
        <ConversationMD
          model={model}
          setNewChat={setNewChat}
          refreshConversations={fetchConversations}
          setCurrentRole={setCurrentRole}
          setChatName={setChatName}
        />
      )}
      {!!conversationId && (
        <ConversationMD
          model={model}
          conversation_id={conversationId}
          setCurrentRole={setCurrentRole}
          targetRef={ref}
          setChatName={setChatName}
        />
      )}
      <dialog
        ref={dialogRef}
        className={showConversationList ? "active right" : "right"}
      >
        {renderConversationList()}
      </dialog>
      {isSharedConversation && (
        <div className="snackbar top active">
          Conversation shared successfully!
        </div>
      )}
      {openShareModal && (
        <dialog className={`auto blue-grey1 ${openShareModal ? "active" : ""}`}>
          <header>
            <h5>Share Conversation</h5>
          </header>
          <div className="">
            <h6 className="small">Choose a method to share</h6>
            <div>
              <label className="radio">
                <input type="radio" />
                <span>Anyone with the link can view this conversation</span>
              </label>
            </div>
            <div className="group row">
              <p>{`${
                process.env.REACT_APP_FRONTEND_URL || "http://localhost:3000"
              }/home/share/conversation/${conversationId}`}</p>
              <button
                className="transparent square small"
                onClick={() => {
                  navigator.clipboard.writeText(
                    `${
                      process.env.REACT_APP_FRONTEND_URL ||
                      "http://localhost:3000"
                    }/home/share/conversation/${conversationId}`
                  );
                }}
              >
                <i>assignment</i>
              </button>
            </div>
          </div>
          <nav className="right-align no-space">
            <button
              className="transparent link"
              onClick={() => setOpenShareModal(false)}
            >
              Cancel
            </button>
            <button className="transparent link" onClick={shareConversation}>
              Share
            </button>
          </nav>
        </dialog>
      )}
    </div>
  );
};
export default Home;
