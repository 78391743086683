import { createStyles } from "@mantine/core";
import { Prism } from "@mantine/prism";
import Markdown from "react-markdown";
import "./MarkdownText.css";
import jsPDF from "jspdf";

const useStyles = createStyles((theme) => ({
  codeBlock: {},
}));

export default function MarkdownText({ text, isTemplateGenerated }: any) {
  const { classes } = useStyles();
  const textSplitted = text.split("```");
  const downloadFile = (data: any) => {
    const doc = new jsPDF();
    const pageWidth = 180;
    const pageHeight = doc.internal.pageSize.height;
    const imageWidth = 50;
    const imageHeight = 50;
    const imageBase64 = "/TrustGenie.png";

    let yPosition = 20;
    const lineHeight = 10;

    const lines = doc.splitTextToSize(data, pageWidth);

    lines.forEach((line: any) => {
      if (yPosition + lineHeight > pageHeight - imageHeight - 10) {
        doc.addPage();
        yPosition = 10;
      }
      doc.text(line, 10, yPosition);
      yPosition += lineHeight;
    });

    const imageYPosition = yPosition + 10;
    if (imageYPosition + imageHeight > pageHeight) {
      doc.addPage();
      doc.addImage(imageBase64, "PNG", 10, 10, imageWidth, imageHeight);
    } else {
      doc.addImage(
        imageBase64,
        "PNG",
        10,
        imageYPosition,
        imageWidth,
        imageHeight
      );
    }

    doc.save("downloaded-file.pdf");
  };

  return (
    <div>
      {textSplitted.map((text: string, index: any) => {
        if (index % 2 === 0) {
          return (
            <Markdown key={index} className="custom-markdown">
              {text}
            </Markdown>
          );
        } else {
          const codeBlock = text.split("\n");
          const language: any = codeBlock[0].split(" ")[0];
          codeBlock.shift();
          console.log("codeBlcok:", codeBlock, isTemplateGenerated);
          return (
            <>
              {codeBlock.length > 0 && isTemplateGenerated ? (
                <button onClick={() => downloadFile(codeBlock)}>
                  Download
                </button>
              ) : (
                <Prism
                  key={index}
                  colorScheme="dark"
                  className={classes.codeBlock}
                  language={language}
                >
                  {codeBlock.join("\n")}
                </Prism>
              )}
            </>
          );
        }
      })}
    </div>
  );
}
