import { useMantineTheme } from "@mantine/core";
import { FileWithPath } from "@mantine/dropzone";

import { useEffect, useState } from "react";
import {
  uploadDocumentsAdmin,
  updateVetorStore,
  uploadLinksAdmin,
  uploadCsvDocumentsAdmin,
} from "../lib/APIService";
import FilesUploadTable from "./FilesUploadTable";

const contextDisplayMapping: any = {
  global: "Global",
  rca: "RCA",
  "anypoint-trust": "Anypoint Trust",
};

const filterDisplayMapping: any = {
  ALL: "ALL",
  DOCUMENTS: "DOCUMENTS",
  LINKS: "LINKS",
};

export default function UploadFiles() {
  const [googleDocsLinks, setGoogleDocsLinks] = useState("");
  const [pdfFiles, setPdfFiles] = useState<FileWithPath[]>([]);
  const [csvFiles, setCsvFiles] = useState<FileWithPath[]>([]);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [uploadfileOpen, setUploadfileOpen] = useState(false);
  const [UploadUrlOpen, setUploadUrlOpen] = useState(false);
  const [uploadcsvfileOpen, setUploadcsvfileopen] = useState(false);
  const [role, setRole] = useState("trust-expert");
  const [refresh, setRefresh] = useState(0);
  const [filter, setFilter] = useState("ALL");
  const [context, setContext] = useState("global");

  const handleFilterChange = (value: string | null) => {
    if (value !== null) {
      setFilter(value);
    }
  };

  const handleContextChange = (value: string | null) => {
    if (value !== null) {
      setContext(value);
    }
  };

  useEffect(() => {
    setUploadSuccess(false);
  }, []);

  const uploadFiles = async () => {
    setLoading(true);
    const response = await uploadDocumentsAdmin(pdfFiles[0], role);
    if (response.status === "success") {
      setPdfFiles([]);
      setUploadSuccess(true);
      await updateVetorStore();

      setTimeout(() => {
        setUploadfileOpen(false);
        setRefresh((prev) => prev + 1);
      }, 1000);
    }
    setLoading(false);
  };

  const uploadCsvFiles = async () => {
    setLoading(true);
    const response = await uploadCsvDocumentsAdmin(csvFiles[0], role);
    if (response.status === "success") {
      setCsvFiles([]);
      setUploadSuccess(true);
      setTimeout(() => {
        setUploadcsvfileopen(false);
        setRefresh((prev) => prev + 1);
      }, 1000);
    }
    setLoading(false);
  };

  const uploadurl = async () => {
    const links = googleDocsLinks
      .split(/\n|,/)
      .map((link) => link.trim())
      .filter((link) => link);

    if (links.length > 0) {
      await uploadLinksAdmin(links, role);
      setGoogleDocsLinks("");
      setUploadSuccess(true);
      try {
        await updateVetorStore();
      } catch {
        console.log("uploaded failed");
      }
      setTimeout(() => {
        setUploadUrlOpen(false);
        setRefresh((prev) => prev + 1);
      }, 2000);
    } else {
      setUploadSuccess(false);
    }
  };

  return (
    <>
      <div style={{ overflow: "hidden" }}>
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              gap: "1rem",
              flexWrap: "wrap",
            }}
          >
            <article
              className="s12 m6 l4"
              style={{
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                padding: "1rem",
                margin: "0.5rem",
              }}
            >
              <p>Upload unstructred files here</p>
              <p style={{ paddingBottom: "16px" }}>
                Limit 10MB per file, .pdf, .doc, .txt file formats
              </p>
              <button
                className="small-round medium"
                onClick={() => setUploadfileOpen(true)}
              >
                <i>add</i>
                <span>Upload Files</span>
              </button>
            </article>

            <article
              className="s12 m6 l4"
              style={{
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                padding: "1rem",
                margin: "0.5rem",
              }}
            >
              <p>Paste Links here</p>
              <p style={{ paddingBottom: "16px" }}>
                Google Drive, Web Links, Link 1, Link 2, Link 3
              </p>
              <button
                className="small-round medium"
                onClick={() => setUploadUrlOpen(true)}
              >
                <i>add</i>
                <span>Upload Url</span>
              </button>
            </article>

            <article
              className="s12 m6 l4"
              style={{
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                padding: "1rem",
                margin: "0.5rem",
              }}
            >
              <p>Upload structured data files here</p>
              <p style={{ paddingBottom: "16px" }}>
                Limit 10MB per file, .csv format only allowed
              </p>
              <button
                className="small-round medium"
                onClick={() => setUploadcsvfileopen(true)}
              >
                <i>add</i>
                <span>Upload Files</span>
              </button>
            </article>
          </div>

          <div style={{ marginTop: "20px", marginBottom: "20px" }}>
            <button>
              <span>{filterDisplayMapping[filter] || "Select filter"}</span>
              <i>arrow_drop_down</i>
              <menu className="no-wrap">
                <a onClick={() => handleFilterChange("ALL")}>ALL</a>
                <a onClick={() => handleFilterChange("DOCUMENTS")}>DOCUMENTS</a>
                <a onClick={() => handleFilterChange("LINKS")}>LINKS</a>
              </menu>
            </button>

            <button style={{ marginLeft: "16px" }}>
              <span>{contextDisplayMapping[context] || "Select context"}</span>
              <i>arrow_drop_down</i>
              <menu className="no-wrap">
                <a onClick={() => handleContextChange("global")}>Global</a>
                <a onClick={() => handleContextChange("rca")}>RCA</a>
                <a onClick={() => handleContextChange("anypoint-trust")}>
                  Anypoint Trust
                </a>
              </menu>
            </button>
          </div>

          <div style={{ overflow: "auto", height: "350px" }}>
            <FilesUploadTable
              refresh={refresh}
              context={context}
              filter={filter}
            />
          </div>
        </div>
      </div>

      {uploadfileOpen && (
        <>
          <div className="overlay blur"></div>
          <dialog
            open={uploadfileOpen}
            className="auto blue-grey1"
            style={{
              display: "flex",
              flexDirection: "column",
              width: "80%",
              maxWidth: "600px",
              margin: "auto",
              padding: "16px",
              boxSizing: "border-box",
            }}
          >
            <div className="row">
              <i className="extra">cloud_circle</i>
              <div className="max">
                <h5 className="no-padding no-margin small upload-header">
                  Upload File
                </h5>
                <p
                  className="no-margin-block-start no-margin grey-text"
                  style={{ fontWeight: 500, fontSize: "15px" }}
                >
                  Select a role for context
                </p>
              </div>
            </div>
            <div style={{ marginLeft: "40px", marginTop: "5px" }}>
              <nav>
                <label className="radio">
                  <input
                    type="radio"
                    name="role"
                    value="trust-expert"
                    onChange={(e) => setRole(e.target.value)}
                  />
                  <span className="text-12">Trust Expert</span>
                </label>
                <label className="radio">
                  <input
                    type="radio"
                    name="role"
                    value="problem-management"
                    className="upload-radio"
                    onChange={(e) => setRole(e.target.value)}
                  />
                  <span className="text-12">Problem Management</span>
                </label>
                <label className="radio">
                  <input
                    type="radio"
                    name="role"
                    value="anypoint-trust"
                    onChange={(e) => setRole(e.target.value)}
                  />
                  <span className="text-12">Anypoint Trust</span>
                </label>
              </nav>
            </div>
            <div style={{ margin: "10px 0px" }}>
              <hr />
            </div>

            <div
              className="upload-content medium padding  center-align"
              style={{
                height: "15rem",
                overflowY: "auto",
              }}
            >
              <div className="padding">
                <div>
                  <i className="upload-icon circle large">cloud_circle</i>
                </div>
                <div style={{ marginTop: "40px" }}>
                  <h6 className="small">
                    Upload PDF, DOC, TXT files to add context to chat
                  </h6>
                  <p style={{ color: "#919399" }}>
                    Limit 10MB per file, .pdf, .doc, .txt file formats
                  </p>
                  <input
                    type="file"
                    accept=".pdf,.doc,.docx,.txt"
                    onChange={(e) => {
                      if (e.target.files) {
                        setPdfFiles(Array.from(e.target.files));
                      }
                    }}
                    multiple
                    style={{ width: "100%" }}
                  />
                </div>

                {!!pdfFiles?.length && (
                  <div style={{ marginTop: "16px" }}>
                    {pdfFiles.map((file) => (
                      <p key={file.name}>{file.name}</p>
                    ))}
                  </div>
                )}
              </div>
            </div>

            {!!uploadSuccess && (
              <article
                className="border light padding"
                style={{ margin: "15px 0px 10px 0px" }}
              >
                <p>Document was uploaded successfully!</p>
              </article>
            )}

            <nav className="right-align no-space">
              <button
                className="transparent link"
                onClick={() => {
                  setUploadfileOpen(false);
                  setUploadSuccess(false);
                }}
              >
                Cancel
              </button>
              <button
                className="transparent link"
                onClick={uploadFiles}
                disabled={!role || !pdfFiles.length || loading}
              >
                {loading ? "Uploading..." : "Upload files"}
              </button>
            </nav>
          </dialog>
        </>
      )}

      {UploadUrlOpen && (
        <>
          <div className="overlay blur"></div>
          <dialog
            open={UploadUrlOpen}
            className="auto blue-grey1"
            style={{
              display: "flex",
              flexDirection: "column",
              width: "80%",
              maxWidth: "600px",
              margin: "auto",
              padding: "16px",
              boxSizing: "border-box",
            }}
          >
            <div className="row">
              <i className="extra">cloud_circle</i>
              <div className="max">
                <h5 className="no-padding no-margin small upload-header">
                  Upload URLs
                </h5>
                <p
                  className="no-margin-block-start no-margin grey-text"
                  style={{ fontWeight: 500, fontSize: "15px" }}
                >
                  Select a role for context
                </p>
              </div>
            </div>

            <div style={{ marginLeft: "40px", marginTop: "5px" }}>
              <nav>
                <label className="radio">
                  <input
                    type="radio"
                    name="role"
                    value="trust-expert"
                    onChange={(e) => setRole(e.target.value)}
                  />
                  <span className="text-12">Trust Expert</span>
                </label>
                <label className="radio">
                  <input
                    type="radio"
                    name="role"
                    value="problem-management"
                    onChange={(e) => setRole(e.target.value)}
                  />
                  <span className="text-12">Problem Management</span>
                </label>
                <label className="radio">
                  <input
                    type="radio"
                    name="role"
                    value="anypoint-trust"
                    onChange={(e) => setRole(e.target.value)}
                  />
                  <span className="text-12">Anypoint Trust</span>
                </label>
              </nav>
            </div>

            <p style={{ marginTop: "16px" }}>
              Paste the Google Drive, Web links here
            </p>
            <div className="field textarea border">
              <textarea
                value={googleDocsLinks}
                placeholder="link1, link2, link3"
                onChange={(e) => setGoogleDocsLinks(e.target.value)}
                style={{ width: "100%", height: "100px" }}
              ></textarea>
            </div>

            {!!uploadSuccess && (
              <article
                className="border light padding"
                style={{ marginTop: "16px" }}
              >
                <p>Document was uploaded successfully!</p>
              </article>
            )}

            <nav className="right-align no-space">
              <button
                className="transparent link"
                onClick={() => {
                  setUploadUrlOpen(false);
                  setUploadSuccess(false);
                }}
              >
                Cancel
              </button>

              <button
                className="transparent link"
                onClick={uploadurl}
                disabled={!googleDocsLinks.trim() || loading}
              >
                {loading ? "Uploading..." : "Upload URLs"}
              </button>
            </nav>
          </dialog>
        </>
      )}

      {uploadcsvfileOpen && (
        <>
          <div className="overlay blur"></div>
          <dialog
            open={uploadcsvfileOpen}
            className="auto blue-grey1"
            style={{
              display: "flex",
              flexDirection: "column",
              width: "80%",
              maxWidth: "600px",
              margin: "auto",
              padding: "16px",
              boxSizing: "border-box",
            }}
          >
            <div className="row">
              <i className="extra">cloud_circle</i>
              <div className="max">
                <h5 className="no-padding no-margin small upload-header">
                  Upload CSV file
                </h5>
              </div>
            </div>

            <div style={{ margin: "10px 0px" }}>
              <hr />
            </div>

            <div
              className="upload-content medium padding  center-align"
              style={{
                height: "15rem",
                overflowY: "auto",
              }}
            >
              <div className="padding">
                <div>
                  <i className="upload-icon circle large">cloud_circle</i>
                </div>
                <div style={{ marginTop: "40px" }}>
                  <h6 className="small">
                    Upload CSV files to add context to chat
                  </h6>
                  <p style={{ color: "#919399" }}>
                    Limit 10MB per file, .csv file formats
                  </p>
                  <input
                    type="file"
                    accept=".csv"
                    onChange={(e) => {
                      if (e.target.files) {
                        setCsvFiles(Array.from(e.target.files));
                      }
                    }}
                    multiple
                    style={{ width: "100%" }}
                  />
                </div>

                {!!csvFiles?.length && (
                  <div style={{ marginTop: "16px" }}>
                    {csvFiles.map((file) => (
                      <p key={file.name}>{file.name}</p>
                    ))}
                  </div>
                )}
              </div>
            </div>

            {!!uploadSuccess && (
              <article
                className="border light padding"
                style={{ margin: "15px 0px 10px 0px" }}
              >
                <p>Document was uploaded successfully!</p>
              </article>
            )}

            <nav className="right-align no-space">
              <button
                className="transparent link"
                onClick={() => {
                  setUploadcsvfileopen(false);
                  setUploadSuccess(false);
                }}
              >
                Cancel
              </button>
              <button
                className="transparent link"
                onClick={uploadCsvFiles}
                disabled={!role || !csvFiles.length || loading}
              >
                {loading ? "Uploading..." : "Upload files"}
              </button>
            </nav>
          </dialog>
        </>
      )}
    </>
  );
}
